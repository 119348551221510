export const ConstantHelper = {

  ALL_COMPANIES_VALUE: 'all',
  DOC_TYPE_SYSTEM: 1,
  DOC_TYPE_USER_SIGNED: 2,
  DOC_TYPE_ADMIN_UPLOADED: 3,
  DOC_TYPE_USER_UPLOADED: 4,
  DOC_TYPE_USER_EMAILED: 5,
  DOC_TYPE_ADMIN_EMAILED: 6,
  DOC_TYPE_DOCTOR_ACKONWLEDEMENT: 7,
  DOC_TYPE_USER_HIPPA: 8,
  DOC_TYPE_USER_IMAGE: 9,
  DOC_TYPE_USER_VIDEO: 10,
  DOC_DOCTOR_TO_DOCTOR: 11,
  DOC_TYPE_RELATED_DOC: 12,


  USER_ROLE_ADMIN: 1,
  USER_ROLE_USER: 2,
  USER_ROLE_SUPER_ADMIN: 3,
  USER_ROLE_DOCTOR: 4,

  // RECAPTCHA_SITE_KEY_CLIENT: '6LeGw4sbAAAAAGYO4HjDAtWYMUl4iwtR0AC4M5ub',
  // RECAPTCHA_SITE_KEY_SECRET: '6LeGw4sbAAAAAM0ScfK3LlkBxxbS7E8jSAjxU1v1',
  RECAPTCHA_SITE_KEY_CLIENT: '6Ler1LYqAAAAAFaHIZedAjg7OhNR6CQjD_zznOPh',
  RECAPTCHA_SITE_KEY_SECRET: '6Ler1LYqAAAAADZXGCcvQQ9ZTJ-c7vMIpuPuXFku',


  AWS_ACCESS_KEY: 'AKIA34FBLFZSMK54RX55',
  AWS_SECRET_KEY: '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',




  AWS_REGION: 'us-west-2',
  AWS_BUCKET: 'ahs-uat',
  AWS_ACL: 'public-read',

  COMPANY_NAME: 'Jamie Wisser MD',
  COMPANY_SLUG: 'jamiewisser',
  COMPANY_DOMAIN: 'www.jamiewisser.com',
  COMPANY_LOGO_URL: 'www.jamiewisser.com',
  COMPANY_SLUG_OTHER: 'other1',


  SUPPORT_EMAIL: 'support@actualhs.com',
  MAIN_SITE: 'medrecordexchange.com',

  ENV_NAME: {
    LOCAL: 'local',
    DEVELOPMENT: 'dev',
    QA: 'QA',
    PRODUCTION: 'prod',
    STAGING: 'uat',
  }


};
